import { ScaleFade } from "@chakra-ui/react"
import React from "react"
import { Layout } from "../../components/layout/Layout"
import { LayoutBody } from "../../components/layout/LayoutBody"
import { BareNav } from "../../components/layout/Nav"
import { EmailVerified } from "../../components/auth/VerifyEmail"
import { PageTitle } from "../../components/typing-test/PageTitle"

// Still works even if user isn't logged in
export default function EmailVerifiedPage() {
  return (
    <Layout>
      <PageTitle>Email Verified</PageTitle>
      <BareNav />
      <LayoutBody size="md">
        <ScaleFade in>
          {/* Don't use navigate because we want to re-load firebase */}
          <EmailVerified onDone={() => (window.location.href = "/")} />
        </ScaleFade>
      </LayoutBody>
    </Layout>
  )
}
